jQuery(document).ready(function($) {
  
if($('body.page-template-finishes').length){


  var chosencolor = 'transparent';
  var previouschosencolor = null;
  var tempchosencolor = '#395583';


      // var colour_picker_html = '<li><h4>Colour Match</h4><input placeholder="Colour..." name="_sf_custom_colour" class="_sf_custom_colour_picker color-picker sf-input-text" type="text" value="#000000" title=""></li>';
      // $('form[data-sf-form-id="4620"]').before(colour_picker_html);


      // SET COLOOR FROM GET VAR PAGE
      function prefill_colour_onload(){
        // set it to the same colour as the dropdown box 2nd value, which should be whatever the arg passed over url is. hacky hack hack!
        var select = $('select[name="_sf_sort_order[]"]');
        var default_color = select.find('option:eq(1)').val();
        default_color = ''+default_color.replace('+asc','');
        if(default_color){
          $('._sf_custom_colour_active').css('background','#'+default_color);
          chosencolor = '#'+default_color;
          $('._sf_custom_colour_active .clear').show();//show remove button for color matching
          // $('._sf_custom_colour_picker span').hide();
        } else {
          $('._sf_custom_colour_active').css('background','transparent');
          $('._sf_custom_colour_active .clear').hide();//show remove button for color matching
        }
        // $('._sf_custom_colour_picker').val(default_color);
      }
      prefill_colour_onload();



      function apply_colour_matching(color){
        console.log('matching color',color);
        var select = $('select[name="_sf_sort_order[]"]');

        if(color){

          // if($(this).hasClass('order_nearest')){
          // var color = $('._sf_custom_colour_picker').val();
          color = color.replace('#','');
          // color = color+encodeURIComponent('+')+"asc";
          color = color+"+asc";
          //change the second item value
          var value = select.find('option:eq(1)').val(color);
          //select it and trigger change
          select.val(color).change();
          // $('._sf_custom_colour_picker span').hide();
          $('._sf_custom_colour_active .clear').show();//show remove button for color matching

        } else {
          select.val(select.find('option:first').val()).change();
          //reset colour
          // $('._sf_custom_colour_picker').css('background','#ebeeef');
          $('._sf_custom_colour_active').css('background','transparent');
          // $('._sf_custom_colour_picker').val('');          
          // $('._sf_custom_colour_picker span').show();
          $('._sf_custom_colour_active .clear').hide();//show remove button for color matching
        }
          
      }


      // $('._sf_custom_colour_picker').iris({
      //   // mode: 'hsv',
      //   // controls: {
      //   //     horiz: 'h', // horizontal defaults to saturation
      //   //     vert: 's', // vertical defaults to lightness
      //   //     strip: 'v' // right strip defaults to hue
      //   // },
      //   // or in the data-default-color attribute on the input
      //   defaultColor: true,
      //   // a callback to fire whenever the color changes to a valid color
      //   change: function(event, ui){
      //     // console.log(ui.color.toString());
      //     var color = ui.color.toString();
      //     $('._sf_custom_colour_picker').css('background',color);
      //   },
      //   // a callback to fire when the input is emptied or an invalid color
      //   clear: function() {
      //     $('._sf_custom_colour_picker').css('background','#ffffff');
      //   },
      //   // hide the color picker controls on load
      //   hide: false,
      //   // show a group of common colors beneath the square
      //   palettes: false
      // });


      // add a button underneath that will serve as our new 'ordering' button.
      // var button = $('<a href="#" class="btn btn-primary _sf_custom_color_order">Order by Nearest Colour Match</a>');
      // var resetbutton = $('<a href="#" class="btn btn-primary _sf_custom_color_reset">Clear Order</a>');
      
      // $('.iris-picker').after(resetbutton);
      // $('.iris-picker').after(button);



      // // show a reset button
      // function show_color_reset(){
      //   if($('select[name="_sf_sort_order[]"]').val()==''){
      //     resetbutton.hide();
      //   } else {
      //     resetbutton.show();
      //   }
      // }
      // show_color_reset();
      // // update it when form changes




      // do stuff when ajax starts and finishes
      $(document).on("sf:ajaxstart", ".searchandfilter", function(e){
        e.stopImmediatePropagation();
        $('body').addClass('search-filter-disabled');
        close_menu();
      });

      $(document).on("sf:ajaxfinish", ".searchandfilter", function(e){
        e.stopImmediatePropagation();
        $('body').removeClass('search-filter-disabled');
        // show_color_reset();
        prefill_colour_onload();
        show_all_select_text();
        scroll_to_results();
      });





      // $('._sf_custom_color_order').click(function (e) {
      //   e.preventDefault();
      //   var select = $('select[name="_sf_sort_order[]"]');

      //   // if($(this).hasClass('order_nearest')){
      //     var color = $('._sf_custom_colour_picker').val();
      //     color = color.replace('#','');
      //     // color = color+encodeURIComponent('+')+"asc";
      //     color = color+"+asc";
      //     //change the second item value
      //     var value = select.find('option:eq(1)').val(color);
      //     //select it and trigger change
      //     select.val(color).change();
          

      //   // } else {
      //   //   select.val(select.find('option:first').val()).change();
      //   // }

      // });

      // $('._sf_custom_color_reset').click(function (e) {
      //   e.preventDefault();
      //   //select first in select which is the reset option.
      //   var select = $('select[name="_sf_sort_order[]"]');
      //   select.val(select.find('option:first').val()).change();
      //   //reset colour
      //   $('._sf_custom_colour_picker').css('background','#ebeeef');
      //   $('._sf_custom_colour_picker').val('');

      // });





      // ====================================================================================================
      // IMAGE UPLOAD AND COLOUR PICKER
      // ====================================================================================================

      // add HTML
      // var titleHtml = $('<h4>Image Colour Picker</h4>');
      // var inputHtml = $('<input type="file" id="aandl_sf_file_upload">');
      // var canvasHtml = $('<canvas width="330" height="0" id="aandl_sf_canvas_picker"></canvas>');
      // $('form[data-sf-form-id="4620"]').parent().prepend(canvasHtml);
      // $('form[data-sf-form-id="4620"]').parent().prepend(inputHtml);
      // $('form[data-sf-form-id="4620"]').parent().prepend(titleHtml);

      // var $picked = $("#picked"); // Just to preview picked colors
      var canvas = $('#aandl_sf_canvas_picker')[0];
      var context = canvas.getContext('2d');


      // $("._sf_image_picker_popup_overlay").on('click',function (e) {
      //   $("#aandl_sf_file_upload").val('');
      // });

      $("#aandl_sf_file_upload").change(function (e) {
        var F = this.files[0];
        var reader = new FileReader();
        reader.onload = imageIsLoaded;
        reader.readAsDataURL(F);  
      });

      // $("._sf_image_picker_popup_overlay").on('click',function (e) {
      //   $('._sf_image_picker_popup_overlay').hide();
      //   $('._sf_image_picker_popup').hide();
      // });

      var hasImage = false;

      function imageIsLoaded(e) {
        var img = new Image();
        img.onload = function(){
          // canvas.width  = this.width;
          // canvas.height = this.height;
          var containerWidth = $('.filter-menu-content').width();
          var ratio =  containerWidth / this.width;
          var height = this.height * ratio;
          if(height>300) height = 300;
          console.log('containerWidth',containerWidth);
          console.log('ratio',ratio);
          console.log('this.height',this.height);
          console.log('this.width',this.width);
          console.log('height',height);
          // canvas.height = 240;
          canvas.height = height;
          // context.drawImage(this, 0, 0);
          hasImage = true;
          coverImg(this, 'contain');
          $('._sf_image_picker_popup').show();
          // $('._sf_image_picker_popup_overlay').show();
        };
        img.src = e.target.result;
      }

      function coverImg(img, type){
        // var coverImg = (img, type) => {
          var imgRatio = img.height / img.width;
          var canvasWidth = canvas.width;
          var canvasHeight = canvas.height;
          var winRatio = canvasHeight / canvasWidth;
          if ((imgRatio < winRatio && type === 'contain') || (imgRatio > winRatio && type === 'cover')) {
            var h = canvasWidth * imgRatio;
            context.drawImage(img, 0, (canvasHeight - h) / 2, canvasWidth, h);
          }
          if ((imgRatio > winRatio && type === 'contain') || (imgRatio < winRatio && type === 'cover')) {
            var w = canvasWidth * winRatio / imgRatio;
            context.drawImage(img, (canvasWidth - w) / 2, 0, w, canvasHeight);
          }
        // }
      }

      var isDragging = false;
      $("#aandl_sf_canvas_picker").mousedown(function(e) {
          isDragging = true;
      }).mousemove(function(e) {
          if(isDragging) {
            set_colour(e);
          }
       }).mouseup(function(e) {
          isDragging = false;
      });

      function set_colour(event){
        var canvas = $("#aandl_sf_canvas_picker");
        var x = event.pageX - canvas.offset().left;
        var y = event.pageY - canvas.offset().top;
        var img_data = context.getImageData(x,y , 1, 1).data;
        var R = img_data[0];
        var G = img_data[1];
        var B = img_data[2]; 
        var rgb = R + ',' + G + ',' + B ;
        var hex = rgbToHex(R,G,B);
        // $('#rgb input').val( rgb );
        // $('#hex input').val('#' + hex);
        // $('._sf_custom_colour_picker').val('#' + hex);
        // $('._sf_custom_colour_picker').iris('color', '#' + hex);
        // chosencolor = '#'+hex;
        tempchosencolor = '#'+hex;
        $('._sf_custom_colour_active').css({'background':tempchosencolor});

        // $('._sf_custom_colour_picker span').hide();


      }
      //canvas click
      $('#aandl_sf_canvas_picker').click(function(event){
        set_colour(event);
      });


      // image picker actions
      $('._sf_image_picker_popup .controls').on('click', '.apply', function(event) {
        chosencolor = tempchosencolor;
        apply_colour_matching(chosencolor);

        // hide the image picker
        $('._sf_custom_colour_active').css({'background':chosencolor});
        $('._sf_image_picker_popup').hide();
      });

      $('._sf_image_picker_popup .controls').on('click', '.cancel', function(event) {

        $('._sf_custom_colour_active').css({'background':chosencolor});
          // $("#aandl_sf_file_upload").val('');//so we can upload again to pick
          $('._sf_image_picker_popup').hide();
      });

      $('._sf_image_picker_popup .controls').on('click', '.load', function(event) {
        hasImage = false;
        $('#aandl_sf_file_upload').trigger('click');
      });



      $('._sf_custom_colour_active').on('click', '.clear', function(event) {
        apply_colour_matching(null);
      });



      // $('#aandl_sf_canvas_picker').click(function(event){
      //   var x = event.pageX - $(this).offset().left;
      //   var y = event.pageY - $(this).offset().top;
      //   var img_data = context.getImageData(x,y , 1, 1).data;
      //   var R = img_data[0];
      //   var G = img_data[1];
      //   var B = img_data[2]; 
      //   var rgb = R + ',' + G + ',' + B ;
      //   var hex = rgbToHex(R,G,B);
      //   // $('#rgb input').val( rgb );
      //   // $('#hex input').val('#' + hex);
      //   // $('._sf_custom_colour_picker').val('#' + hex);
      //   $('._sf_custom_colour_picker').iris('color', '#' + hex);
      //   // $picked.append("<span style='background:#"+hex+"'>#"+hex+"</span>");
      // });

      function rgbToHex(R, G, B) {
        return toHex(R) + toHex(G) + toHex(B);
      }

      function toHex(n) {
        n = parseInt(n, 10);
        if (isNaN(n))  return "00";
        n = Math.max(0, Math.min(n, 255));
        return "0123456789ABCDEF".charAt((n - n % 16) / 16)  + "0123456789ABCDEF".charAt(n % 16);
      }



      // search filter pagination - for some reason the built in one doesnt work.
      $('body.page-template-finishes').on('click', '.sf_pagination a', function(event) {
        // event.preventDefault();
      });


      //main button 'pick from image'
      $('a._sf_image_picker').on('click', function(e) {
        if(hasImage){
          e.preventDefault();
          pickr.hide();
          $('._sf_image_picker_popup').show();
        }
      });




      // make last option on page count 'Show All' hack.
      function show_all_select_text(){
        $('.sf-field-posts_per_page select option:contains("400")').text('Show All');
      }
      show_all_select_text(); //this is also called on the searchfilters ajax oncomplete thingy



      // //detect click outside popup
      window.addEventListener('click', function(e){   
        if (document.getElementById('_sf_image_picker_popup').contains(e.target)){
          // Clicked in box
          // console.log('clicked in');
        } else{
          // $('._sf_image_picker_popup').hide();//hide
          // $("#aandl_sf_file_upload").val('');//so we can upload again to pick
          // Clicked outside the box
          // console.log('clicked out');
        }
      });



      // scroll to results
      function scroll_to_results(){

        var navbarHeight = $('#wrapper-navbar').height();
        var noticeHeight = $('#important-notice').height();
        var results = $('.scroll-to-results').offset();
        var offset = results.top - 30;

        window.scrollTo({top: offset, behavior: 'smooth'});

      }




      // menu trigger
      $('.filter-menu-trigger').click(function(e) {
        e.preventDefault();
        $('.filters-overlay').addClass('active');
        $('.search-filter-filters').addClass('active');
      });
      $('.filters-overlay, .filter-menu-close').click(function(e) {
        e.preventDefault();
        close_menu();
      });
      function close_menu(){
        $('.filters-overlay').removeClass('active');
        $('.search-filter-filters').removeClass('active');        
      }











      $('._sf_custom_colour_picker').click(function(e) {
        e.preventDefault();
        pickr.show();

        //cancel the image picker if open
        $('._sf_custom_colour_active').css({'background':chosencolor});
        $('._sf_image_picker_popup').hide();
      });






      const pickr = Pickr.create({
          // el: '._sf_custom_colour_picker_popup',
          el: '._sf_custom_colour_picker_popup',
          theme: 'classic', // or 'monolith', or 'nano'
          default: '#395583',
          lockOpacity: true,
          useAsButton: true,
          // container: '._sf_custom_colour_picker_popup',
          inline: true,
          showAlways: true,
          // swatches: [
          //     'rgba(244, 67, 54, 1)',
          //     'rgba(233, 30, 99, 0.95)',
          //     'rgba(156, 39, 176, 0.9)',
          //     'rgba(103, 58, 183, 0.85)',
          //     'rgba(63, 81, 181, 0.8)',
          //     'rgba(33, 150, 243, 0.75)',
          //     'rgba(3, 169, 244, 0.7)',
          //     'rgba(0, 188, 212, 0.7)',
          //     'rgba(0, 150, 136, 0.75)',
          //     'rgba(76, 175, 80, 0.8)',
          //     'rgba(139, 195, 74, 0.85)',
          //     'rgba(205, 220, 57, 0.9)',
          //     'rgba(255, 235, 59, 0.95)',
          //     'rgba(255, 193, 7, 1)'
          // ],

          components: {

              // Main components
              preview: false,
              opacity: false,
              hue: true,

              // Input / output Options
              interaction: {
                  hex: false,
                  rgba: false,
                  hsla: false,
                  hsva: false,
                  cmyk: false,
                  input: false,
                  clear: false,
                  cancel: true,
                  save: true
              }
          },

          i18n: {

              // Strings visible in the UI
             'ui:dialog': 'color picker dialog',
             'btn:toggle': 'toggle color picker dialog',
             'btn:swatch': 'color swatch',
             'btn:last-color': 'use previous color',
             'btn:save': 'APPLY COLOUR MATCHING',
             'btn:cancel': '&#x2715;',
             'btn:clear': 'CLEAR',

             // Strings used for aria-labels
             'aria:btn:save': 'save and close',
             'aria:btn:cancel': 'cancel and close',
             'aria:btn:clear': 'clear and close',
             'aria:input': 'color input field',
             'aria:palette': 'color selection area',
             'aria:hue': 'hue selection slider',
             'aria:opacity': 'selection slider'
          }
      });





      pickr.on('init', instance => {
          // console.log('Event: "init"', instance);
          $('._sf_custom_colour_active').css({'background':chosencolor});
          $('.pcr-app').height(0);
          pickr.hide();

      }).on('hide', instance => {
          console.log('Event: "hide"', instance);
          // apply_colour_matching(chosencolor);
          $('._sf_custom_colour_active').css({'background':chosencolor});
          $('.pcr-app').height(0);

      }).on('show', (color, instance) => {
          // console.log('Event: "show"', color, instance);
          // setTimeout(function(){
          //   $('.pcr-color-palette .pcr-picker').trigger('click');
          // },10);
          $('._sf_custom_colour_active').css({'background':chosencolor});
          if(chosencolor=='transparent') {
            pickr.setColor(tempchosencolor,true);
          } else {
            pickr.setColor(chosencolor,true);
          }
          $('.pcr-app').height('auto');

      }).on('save', (color, instance) => {
          console.log('Event: "save"', color, instance);
          // if(color) {
          //   chosencolor = color.toHEXA().toString();
          //   $('._sf_custom_colour_active').css({'background':chosencolor});
          // } else {
          //   chosencolor = null;
          //   $('._sf_custom_colour_active').css({'background':'transparent'});
          // }
          chosencolor = color.toHEXA().toString();
          // $('._sf_custom_colour_active').css({'background':chosencolor});
          apply_colour_matching(chosencolor);
          pickr.hide();

      }).on('clear', instance => {
          console.log('Event: "clear"', instance);
          // $('._sf_custom_colour_picker span').show();
          // chosencolor = null;

      }).on('change', (color, source, instance) => {
          // console.log(color.toHEXA().toString());
          tempchosencolor = color.toHEXA().toString();
          $('._sf_custom_colour_active').css({'background':tempchosencolor});
          // $('._sf_custom_colour_picker span').hide();

      }).on('changestop', (source, instance) => {
          // console.log('Event: "changestop"', source, instance);

      }).on('cancel', instance => {
          console.log('Event: "cancel"', instance);
          // chosencolor = previouschosencolor;
          // $('._sf_custom_colour_active').css({'background':chosencolor});
          pickr.hide();

      }).on('swatchselect', (color, instance) => {
          // console.log('Event: "swatchselect"', color, instance);

      });



}


// SWATCH SELECT PAGE
if($('body.page-template-finishes-swatch-request').length){
  //close the 'added' notice
  $('.added-swatch-notice .dismiss').click(function(e) {
    e.preventDefault();
    $('.added-swatch-notice').fadeOut();
  });

  // // auto dismiss
  // if($('.added-swatch-notice').is(":visible")){
  //   setTimeout(function(){
  //     $('.added-swatch-notice').fadeOut();
  //   },5000);
  // }

}


var ajaxurl = '/wp-admin/admin-ajax.php';


// SWATCH DETAIL PAGE
if($('body.single-finishes').length){

  $('.swatch_request_add').click(function(e) {
    e.preventDefault();
    if($(this).hasClass('processing')) return false;
    $(this).addClass('processing');
    var id = jQuery(this).attr("data-id");
    var nonce = jQuery(this).attr("data-nonce");
    // var ajaxurl = jQuery(this).attr("href");
    var me = $(this);
    // var ajaxurl =  ajax_params.ajax_url;
    jQuery.ajax({
             type : "post",
             dataType : "json",
             url : ajaxurl,
             data : {action: "swatch_request_add", id : id, nonce: nonce},
             success: function(response) {
              me.removeClass('processing');
              console.log(response.result);
                if(response.result == "added") {
                  // var data = $.parseJSON(response);
                   // jQuery("#vote_counter").html(response.vote_count)
                   // alert(response.result);
                   var ss = '';
                   if(response.count>1) ss = 's';
                   $('.swatch-request-cart .itemcount').html(response.count+' Item'+ss);
                   var added = $('<span class="in-basket"><i class="fa fa-check"></i> Added to Swatch Request Cart</span>');
                   $('.swatch_request_add').after(added).remove();
                   // alert("Added");
                }
                else if(response.result == "exists") {
                   // alert("Exists");
                } else {
                   // alert("Failed");
                }
             }
          });
  });



}


if($('body.page-template-finishes-swatch-request').length){

  $('.cart-item .remove').click(function(e) {
    e.preventDefault();
    if($(this).parent().hasClass('processing')) return false;
    $(this).parent().addClass('processing');
    var id = $(this).attr("data-id");
    var nonce = jQuery(this).attr("data-nonce");
    // var ajaxurl = jQuery(this).attr("href");
    var row = $(this).parent();
    // var ajaxurl =  ajax_params.ajax_url;
    jQuery.ajax({
             type : "post",
             dataType : "json",
             url : ajaxurl,
             data : {action: "swatch_request_remove", id : id, nonce: nonce},
             success: function(response) {
              row.removeClass('processing');
              console.log(response.result);
                if(response.result == "removed") {
                  // var data = $.parseJSON(response);
                   // jQuery("#vote_counter").html(response.vote_count)
                   // alert(response.result);
                   // var ss = '';
                   // if(response.count>1) ss = 's';
                   // $('.swatch-request-cart a').html(response.count+' Item'+ss);
                   // var added = $('<span class="in-basket"><i class="fa fa-check"></i> Added to Swatch Request Cart</span>');
                   row.remove();
                   if(response.count==0) location.reload();
                   update_form();
                   // alert("Added");
                }
                else if(response.result == "exists") {
                   // alert("Exists");
                } else {
                   // alert("Failed");
                }
             }
          });

    function update_form(){
      var str = "";
      $.each($('.requested-swatches-list .cart-item'), function(index, val) {
        str = str + $(this).find('.title').html() + "\r\n";
      });
      $('#input_5_6').html(str);
    }
  });

}





// if($('.finishes-module').length){
//   // TweenMax.to($('.finishes-module .swatch-preview .strip'),2,{left:-1774});

//   // function animate_swatch_preview(){
//   //   $('.finishes-module .swatch-preview .strip').css('left',0);
//   //   $('.finishes-module .swatch-preview .strip').animate({
//   //     left: -1774,
//   //     },
//   //     10000, "linear",function() {
//   //       animate_swatch_preview();
//   //   });
//   // }

//   // animate_swatch_preview();
// }




});