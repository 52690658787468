jQuery(document).ready(function($) {


	//portfolio page filters
	// jQuery(document).ready(function($) {
	// $(function() {
	// 	$("#portfolio-list").filterable();
	// 	});
	// });

	//header search	j
	$('#header-search-trigger').click(function(e){
		e.preventDefault()
			$('#searchContainer').slideToggle(300);
			$('#header-search').toggleClass('grey');
	});

	//feature slider
    $('.feature-slider a').click(function(e) {
        $('.featured-posts section.featured-post').css({
            opacity: 0,
            visibility: 'hidden'
        });
        $(this.hash).css({
            opacity: 1,
            visibility: 'visible'
        });
        $('.feature-slider a').removeClass('active');
        $(this).addClass('active');
        e.preventDefault();
    });

	//social share floating bar thing
	$("body").not('.site-id-1').children('#social-share-icons').floatingSocialShare({
	  place: "top-left", // alternatively content-left, content-right, top-right
	  counter: true, // set to false for hiding the counters of buttons
	  twitter_counter: false, // Twitter API does not provide counters without API key, register to https://opensharecount.com/
	  buttons: [ // all of the currently available social buttons
	    "facebook",
	    "twitter",
	    "linkedin", 
	    "google-plus",
	    "envelope",
	  ],
	  title: document.title, // your title, default is current page's title
	  url: window.location.href,  // your url, default is current page's url
	  email: 'info@ashandlacy.com', // email for envelope button
	  text: { // the title of tags
	    'default': 'share with ', 
	    'facebook': 'share with facebook', 
	    'google-plus': 'share with g+'
	  },
	  text_title_case: false, // if set true, then will convert share texts to title case like Share With G+
	  description: $('meta[name="description"]').attr("content"), // your description, default is current page's description
	  media: $('meta[property="og:image"]').attr("content"), // pinterest media
	  popup_width: 400, // the sharer popup width, default is 400px
	  popup_height: 300 // the sharer popup height, default is 300px
	});




	//accordian mobile
	$('.container-accordion-mobile label').click(function(e) {
		$('.container-accordion-mobile .home-sub-container').slideUp();
		var content = $(this).next();
		if($(content).is(':visible')){
			$(content).slideUp();
		} else {
			$(content).slideDown();
		}
	});



	//ilightbox project map gallery
	$('.ilightboxcustom').click(function(e) {
		e.preventDefault();
		e.stopPropagation();
		var images = $(this).attr('ilightbox-gallery');
		var titles = $(this).attr('ilightbox-gallery-titles');

		var arr = [];

		if(images){
			images = images.split(',');
			titles = titles.split('|');

			for (var i = 0; i < images.length; i++) {
				var args = {};
				args.url = images[i];
				args.title = titles[i];
				console.log(args);
				//args.caption = titles[i];
				arr.push(args);
			}
			console.log(arr);
			$.iLightBox(arr, {});
		}

	});


	//ilightbox gallery support - rel="ilightbox[gallery-name]"
	var groupsArr = [];
	$('[rel^="ilightbox["]').each(function () {
		var group = this.getAttribute("rel");
		$.inArray(group, groupsArr) === -1 && groupsArr.push(group);
	});
	$.each(groupsArr, function (i, groupName) {
		$('[rel="' + groupName + '"]').iLightBox();
	});	



	$('a.nav-link[href="#"]').click(function(e) {
		e.preventDefault();
	});





	// =============================================================
	// ================    home page slider     ====================
	// =============================================================
	var sliderWrapper=$("#homepageslider");
	var slider=$("#homepageslider .slider");
	var progress=$("#homepageslider .progress_ring");
		console.log('slider start');

	slider.on("cycle-initialized",function(e,ops){
		sliderWrapper.addClass("ready");
		progress.addClass("active");
		// slider.find(".first-slide").addClass("active");
		setTimeout(function(){
			// slider.find(".slide:first-child").addClass("active");
			// slider.find(".first-slide").addClass("actives");
			$("#homepageslider .slider .first-slide").addClass("active");
			// console.log('slider init', slider);
		},100);
	});

	// setTimeout(function(){
	// 	slider.find(".slide:first-child").addClass("active");
	// 	slider.find(".first-slide").addClass("actives");
	// 	console.log('slider init', slider);
	// },500);

	slider.on("cycle-before",function(t,i,s,a,o){
		var r = $(a);
		progress.addClass("transition");
		setTimeout(function(){
			r.addClass("active");
		},1);
	});

	slider.on("cycle-after",function(t,i,s,a,o){
		$(a).siblings().removeClass("active");
		progress.removeClass("active");
		progress.removeClass("transition");
		setTimeout(function(){
			progress.addClass("active")},1);
	});


	slider.cycle({
		slides:"> .slide",
		next:".cycle-next",
		prev:".cycle-prev",
		speed:1000,
		timeout:7000
	});

	$(".image-map-lightbox, .container-image-map .wireframe").click(function(e){
		e.preventDefault();
		e.stopPropagation();
		$("#home-canvas-container-overlay").fadeIn(400);
		$("#home-canvas-container").delay(200).fadeIn(400);
		$("#home-canvas-container-overlay-close").delay(0).fadeIn(400);
	});
	
	$("#home-canvas-container-overlay-close").click(function(e){
		e.preventDefault();
		e.stopPropagation();
		$("#home-canvas-container-overlay-close").fadeOut(200);
		$("#home-canvas-container").delay().fadeOut(200);
		$("#home-canvas-container-overlay").delay().fadeOut(200);
	});
	
	// ================    home page slider end     ====================







	// =============================================================
	// ================    HERO page slider     ====================
	// =============================================================
	var sliderWrapper=$(".hero_slider_wrapper");
	var slider=$(".hero_slider_wrapper .hero_slider");
	var progress=$(".hero_slider_wrapper .progress");
	// var next=slider.find('.cycle-next');
	// var prev=slider.find('.cycle-prev');


	var  effects = [
		'zoomIn',
		'zoomOut',
		'panUp',
		'panDown',
		'panLeft',
		'panRight',
		'diagTopLeftToBottomRight',
		'diagTopRightToBottomLeft',
		'diagBottomRightToTopLeft',
		'diagBottomLeftToTopRight',
	];
	// $(".hero_slider_wrapper .hero_slider .image").addClass('effect-'+effect);
	$.each($(".hero_slider_wrapper .hero_slider .image"), function(index, val) {
		var effect = effects[Math.floor(Math.random()*effects.length)];
		 $(this).addClass('effect-'+effect);
	});

	slider.on("cycle-initialized",function(e,t,a,o,r){
		sliderWrapper.addClass("ready");
		progress.addClass("active");
		setTimeout(function(){
			slider.find("li:first-child").addClass("active");
		},10);
	});

	slider.on("cycle-before",function(t,i,s,a,o){
		var r = $(a);
		progress.addClass("transition");
		setTimeout(function(){
			r.addClass("active");
		},1);
	});

	slider.on("cycle-after",function(t,i,s,a,o){
		$(a).siblings().removeClass("active");
		progress.removeClass("active");
		progress.removeClass("transition");
		setTimeout(function(){
			progress.addClass("active")},1);
	});


	slider.cycle({
		slides:"> li",
		next:".cycle-next",
		prev:".cycle-prev",
		speed:1000,
		timeout:7000
	});

	// sliderWrapper.hover(function() {
	// 	sliderWrapper.addClass('hover');
	// }, function() {
	// 	sliderWrapper.removeClass('hover');
	// });
	// ================    home page slider end     ====================



	if($('.page-template-project-gallery').length){

		$(window).on('load', function () {
			// isotope
			$('.page-template-project-gallery .isotope-grid').show();
			var $projectGalleryGrid = $('.page-template-project-gallery .isotope-grid').isotope({
			  // options
			});
			// $('.page-template-project-gallery .isotope-grid').hide();
			// filter items on button click
			$('.page-template-project-gallery .isotope-filters').on( 'click', '.filter-item', function(e) {
				e.preventDefault();
				$('.page-template-project-gallery .isotope-filters .filter-item').removeClass('active');
				$(this).addClass('active');
			  var filterValue = $(this).attr('data-filter');
			  $projectGalleryGrid.isotope({ filter: filterValue });
			});

			
			$('.page-template-project-gallery .isotope-grid-loading').hide();

			// function onArrange(){
			// }
			// $projectGalleryGrid.on( 'arrangeComplete', onArrange );

			// $(".page-template-project-gallery .isotope-gri").on( 'arrangeComplete', function( event, filteredItems ) {
			// 		$('.page-template-project-gallery .isotope-grid').fadeIn();
			// 		$('.page-template-project-gallery .isotope-grid-loading').hide();
			// 		console.log('done');
			// });

		});
	}


});